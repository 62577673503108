<template>
  <div>
    <b-card no-body class="account-top">
      <b-card-body>
        <div class="account-title">
          <feather-icon class="icon-account" icon="UserIcon" />
          <span class="text-account">
            {{ $t("Set Notification Preferences") }}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <!-- form -->
    <b-form class="security-section">
      <b-row>
        <span class="password-title">
          {{ $t('Please choose your preferred method to be notified in case of device alerts') }}:
        </span>
        <br><br>
      </b-row>
      <b-row class="form-inputs">
        <b-form-checkbox v-model="emailNotificationCheck">
        </b-form-checkbox>
        <b-col md="6">
          <b-form-group label-for="account-email-notifications" :label="$t('Email Notifications')">
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="form-inputs">
        <b-form-checkbox v-model="pushNotificationCheck">
        </b-form-checkbox>
        <b-col md="6">
          <b-form-group label-for="account-push-notifications" :label="$t('Push Notifications')">
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="none" class="form-btn mt-1 mr-1"
            @click="updateAlerts">
            {{ $t("Save Changes") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend, BFormCheckbox
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OneSignal from "onesignal-vue";


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      //validators
      required,
    }
  },
  computed: {
    userAlerts() {
      this.pushNotificationCheck = this.$store.state.users.userAlerts.notify_by_push
      this.emailNotificationCheck = this.$store.state.users.userAlerts.notify_by_email
      return this.$store.state.users.userAlerts
    },
    currentUser: {
      get() {
        return this.$store.state.users.currentUser;
      }
    },
    emailNotificationCheck: {
      get() {
        return this.$store.state.users.userAlerts.notify_by_email;
      },
      set(data) {
        this.$store.state.users.userAlerts.notify_by_email = data;
      },
    },
    pushNotificationCheck: {
      get() {
        return this.$store.state.users.userAlerts.notify_by_push
      },
      set(data) {
        if (!!data) {
          this.requestPushNotificationsPermission();
        }

        this.$store.state.users.userAlerts.notify_by_push = data;
      }
    }
  },
  methods: {
    async updateAlerts() {
      let data = {
        notify_by_push: this.pushNotificationCheck,
        notify_by_email: this.emailNotificationCheck,
      }

      const externalId = this.currentUser.email;

      await this.$OneSignal.setExternalUserId(externalId);

      const isPushNotificationsEnabled = await this.$OneSignal.isPushNotificationsEnabled();

      if (data.notify_by_push && !isPushNotificationsEnabled) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Change Failed',
            icon: 'AlertTriangleIcon',
            text: "Dear user, you must allow site notifications in your browser to use this setting",
            variant: 'danger'
          },
        })
      } else {
        this.$store.dispatch("users/updateUsersAlerts", { context: this, data: data, id: this.userAlerts.id })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Changed Successfully',
                icon: 'RefreshCwIcon',
                variant: 'success',
              },
            })
          })
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Change Failed',
                icon: 'AlertTriangleIcon',
                text: error.response.data,
                variant: 'danger'
              },
            })
          })
      }

    },
    async requestPushNotificationsPermission() {
      await this.$OneSignal.showSlidedownPrompt({
        force: true,
      });
    }
  },
  created() {
    this.$store.dispatch("users/getUsersAlerts", { context: this })
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/pages/page-company.scss';
</style>