<template>
  <div>
    <b-card no-body class="account-top">
      <b-card-body>
        <div class="account-title">
            <feather-icon
              class="icon-account"
              icon="UserIcon"
            />
          <span class="text-account">
              {{$t("Change Password")}}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <validation-observer ref="simpleRules">
    <!-- form -->
    <b-form 
        class="security-section"
      >

      <b-row class="form-inputs">
        <!-- current password -->
        <b-col md="6">
          <b-form-group
            :label="$t('Current Password')"
            label-for="account-old-password"
          >
          <validation-provider
                #default="{ errors }"
                name="CurrentPassword"
                vid="CurrentPassword"
                rules="required|password"
              >
            <b-input-group class="input-group-merge">
              <b-form-input
                id="account-old-password"
                v-model="passwordValueOld"
                name="old-password"
                :type="passwordFieldTypeOld"
                :state="errors.length > 0 ? false:null"
                placeholder="*************"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconOld"
                  class="cursor-pointer"
                  @click="togglePasswordOld"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ current password -->
      </b-row>
      <b-row class="form-inputs">
        <!-- new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-new-password"
            :label="$t('New Password')"
          >
          <validation-provider
                #default="{ errors }"
                name="Password"
                vid="Password"
                rules="required|password"
              >
            <b-input-group 
            class="input-group-merge"
             :class="errors.length > 0 ? 'is-invalid':null">
              <b-form-input
                id="account-new-password"
                v-model="newPasswordValue"
                :type="passwordFieldTypeNew"
                :state="errors.length > 0 ? false:null"
                name="new-password"
                :placeholder="$t('New Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconNew"
                  class="cursor-pointer"
                  @click="togglePasswordNew"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <!--/ new password -->
      </b-row>
      <b-row class="form-inputs">
        <!-- Confirm new password -->
        <b-col md="6">
          <b-form-group
            label-for="account-retype-new-password"
            :label="$t('Confirm New Password')"
          >
          <validation-provider
                #default="{ errors }"
                name="Confirm Password"
                rules="required|confirmed:Password"
          >
            <b-input-group 
            class="input-group-merge"
             :class="errors.length > 0 ? 'is-invalid':null"
            >
              <b-form-input
                id="account-retype-new-password"
                v-model="RetypePassword"
                :type="passwordFieldTypeRetype"
                :state="errors.length > 0 ? false:null"
                name="retype-password"
                :placeholder="$t('Confirm New Password')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  :icon="passwordToggleIconRetype"
                  class="cursor-pointer"
                  @click="togglePasswordRetype"
                />
              </b-input-group-append>
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
          </b-form-group>
        </b-col>
        <!-- / Confirm new password -->
      </b-row>
      <b-row>
        <b-col cols="12">
          <span class="password-title">
            {{$t("Password requirements:")}}
          </span>
          <ul class="password-requirements">
            <li>
              {{$t("Minimum 8 characters long - the more, the better")}}
            </li>
            <li>
              {{$t("At least one lowercase character")}}
            </li>
            <li>
              {{$t("At least one number, symbol, or whitespace character")}}
            </li>
          </ul>
        </b-col>
        <!--/ retype password -->
        <!-- buttons -->
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="none"
            class="form-btn mt-1 mr-1"
            @click="validationForm"
          >
            {{$t("Save Changes")}}
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="none"
            class="form-btn mt-1"
          >
          {{$t("Discard")}}
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',

      //validators
      required,
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submit()
        }
      })
    },
    submit()
    {
      let data = {
        current_password:this.passwordValueOld,
        new_password:this.newPasswordValue,
        re_new_password:this.RetypePassword
        }
        
    this.$store.dispatch("users/postNewPassword", { context: this, data: data })
      .then(() => 
      {
          this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Password Changed Successfully',
                    icon: 'RefreshCwIcon',
                    variant: 'success',
                  },
                })
      })
      .catch(error => 
      {
        this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Password Change Failed',
                icon: 'AlertTriangleIcon',    
                text: error.response.data,
                variant: 'danger'
                },
            })
      })
  },
  },
}
</script>
<style lang="scss">
@import '@core/scss/base/pages/page-company.scss';
</style>