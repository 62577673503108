<template>
  <div>
    <b-card no-body class="account-top">
      <b-card-body>
        <div class="account-title">
          <feather-icon
              class="icon-account"
              icon="UserIcon"
          />
          <span class="text-account">
            {{ $t("Profile Details") }}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <div class="account-body">
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
            <b-avatar
            ref="previewEl"
            :variant="`light-${resolveUserRoleVariant(userData.role)}`"
            :text="avatarText(`${currentUser.first_name}  ${currentUser.last_name}`)"
            size="100px"
            circle
          />
        </template>
      </b-media>

      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <b-col
              cols="12"
              md="6"
          >
            <!-- Field: Name -->
            <b-form-group
                :label="$t('First Name')"
                label-for="user-first-name"
            >
              <b-form-input
                  @input="enableChange()"
                  id="user-first-name"
                  :placeholder="$t('First Name')"
                  v-model="currentUser.first_name"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Last Name')"
                label-for="user-last-name"
            >
              <b-form-input
                  id="user-last-name"
                  :placeholder="$t('Last Name')"
                  v-model="currentUser.last_name"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Email')"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  type="email"
                  :placeholder="$t('Email')"
                  v-model="currentUser.email"
                  disabled
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Organization')"
                label-for="organization"
            >
              <b-form-input
                  id="organization"
                  :placeholder="$t('Organization')"
                  v-model="currentUser.company"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Status -->
          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Mobile Number')"
                label-for="user-phone"
            >
              <b-form-input
                  id="user-phone"
                  :placeholder="$t('Mobile Number')"
                  v-model="currentUser.phone_number"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Address')"
                label-for="user-address"
            >
              <b-form-input
                  id="user-address"
                  placeholder="Your Address"
                  v-model="currentUser.address"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('State')"
                label-for="state"
            >
              <b-form-input
                  id="state"
                  :placeholder="$t('State')"
                  v-model="currentUser.state"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Zip Code')"
                label-for="zipcode"
            >
              <b-form-input
                  v-model="currentUser.zip_code"
                  id="zipcode"
                  :placeholder="$t('Zip Code')"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>

          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                :label="$t('Country')"
                label-for="country"
            >
              <v-select
                  id="country"
                  :placeholder="countryName.find(l=>l.value == currentUser.country) ? countryName.find(l=>l.value == currentUser.country).text : ''"
                  v-model="selectedContry"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="countryName"
                  :selectable="option => ! option.value.includes('select_country')"
                  label="text"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>
          <b-col
              cols="12"
              md="6"
          >
            <b-form-group
                label-for="language"
                :label="$t('Language')"
            >
              <v-select
                  id="language"
                  :placeholder="languageName.find(l=>l.value == currentUser.default_language) ? languageName.find(l=>l.value == currentUser.default_language).text : ''"
                  v-model="selectedLanguage"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :selectable="option => ! option.value.includes('select_language')"
                  :options="languageName"
                  label="text"
                  :searchable="false"
                  @input="enableChange()"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <div class="form-buttons">
        <b-button
            variant="none"
            class="form-btn mr-2"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            :disabled="isChanged"
            @click="formSubmitted"
        >
          {{ $t("Save Changes") }}
        </b-button>
        <b-button
            variant="none"
            class="form-btn"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="resetForm()"
        >
          {{ $t("Discard") }}
        </b-button>
      </div>
      <div class="delete-account">
        <h3>{{ $t("Delete Account") }}</h3>
        <hr>
        <div class="col-lg-9 content-spacing-0">
          <b-alert
              class="alert-body"
              variant="none"
              show
          >
            <h4 class="alert-heading">
              <feather-icon
                  class="mr-25"
                  icon="AlertTriangleIcon"
              />
              {{ $t("Are you sure you want to delete your account?") }}
            </h4>
            <div class="alert-body">
              <span>{{ $t("Once you delete your account, there is no going back. Please be certain.") }}</span>
            </div>
          </b-alert>
          <validation-observer ref="deleteForm">
              <b-form-group>
                <validation-provider
                            #default="{ errors }"
                            name="Check Confirm"
                            rules="required"
                          >
              <b-form-checkbox
                  id="register-privacy-policy"
                  v-model="checkConfirmDelete"
                  name="checkbox-1"
                  @input="setValueCheck"
              >
                {{ $t("I confirm my account deactivation") }}
              </b-form-checkbox>
            <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="none"
              class="btn-deactivate mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              @click="showModal"
          >
            {{ $t("Deactivate Account") }}
          </b-button>
          </validation-observer>
    </div>
  </div>
        <b-modal
            id="modal-center"
            ref="modal-center-delete"
            centered
            ok-title="Cancel account"
            cancel-title="Nervermind, I don't want to cancel"
            @ok="cancelAccount"
            @cancel="clean"
        >
          <div class="modal-text">
            <span class="modal-title">{{ $t("Delete Account") }}</span>
            <span class="modal-description">
              {{ $t("We are sad to see you go...") }}<br/>
              {{ $t("Tell us why, every feedback helps!") }}
            </span>
          </div>
          <validation-observer ref="modalForm">
            <b-form-group>
              <validation-provider
                            #default="{ errors }"
                            name="Check Confirm"
                            rules="required"
                            v-model="checkList"
                          >
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  value="Technical Problems"
                  :disabled="condition"
              >
                {{$t("Technical problems")}}
              </b-form-checkbox>
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  value="Data Tools"
                  :disabled="condition"
              >
              {{$t("I don't know how to use the data and tools")}}
              </b-form-checkbox>
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  value="Company Closed"
                  :disabled="condition"
              >
              {{$t("The company closed")}}
              </b-form-checkbox>
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  value="Lack Resources"
                  :disabled="condition"
              >
              {{$t("I lack resources I need")}} 
              </b-form-checkbox>
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  value="Switched Service"
                  :disabled="condition"
              >
              {{$t("I switched to another service")}}
              </b-form-checkbox>
              <b-form-checkbox
                  class="check-delete"
                  v-model="checkList"
                  name="checkbox-1"
                  :disabled="condition"
                  value="Other"
              >
              {{$t("Other")}}
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
              <b-form-textarea
                  id="textarea-default"
                  placeholder="Anything else you'd like to share? (optional)"
                  rows="3"
                  v-model="description"
              />
            <!-- Field: Current Password -->
            <b-form-group
                :label="$t('Current Password* :')"
                label-for="user-first-name"
                class="btn-confirm-password"
            > 
            <b-form-input
                  id="confirm password"
                  placeholder="Set your password"
                  type="password"
                  v-model="confirmPassword"
              />
            </b-form-group>             
              </validation-provider>
            </b-form-group>
          </validation-observer>
        </b-modal>
      </div>
  </div>
</template>

<script>
import {
  BButton, BMedia, BAvatar, BRow, BCol, BFormGroup, BFormInput, VBModal, BModal, BFormTextarea,
  BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox, BAlert, BCardText, BLink,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {avatarText} from '@core/utils/filter'
import vSelect from 'vue-select'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {ref} from '@vue/composition-api'
import useUsersList from './useUsersList'
import BCardCode from '@core/components/b-card-code'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BFormTextarea,
    BModal,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BAlert,
    BCardText,
    BLink,
    BCardCode,
    vSelect,
    ToastificationContent,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      selectedContry: '',
      selectedLanguage: '',
      selectedTimeZone: this.$t('Select Time Zone'),
      selectedCurrency: '',
      countryName: [
        {value:"AF", text:"Afghanistan"},
        {value:"AL", text:"Albania"},
        {value:"DZ", text:"Algeria"},
        {value:"AS", text:"American Samoa"},
        {value:"AD", text:"Andorra"},
        {value:"AO", text:"Angola"},
        {value:"AI", text:"Anguilla"},
        {value:"AQ", text:"Antarctica"},
        {value:"AG", text:"Antigua and Barbuda"},
        {value:"AR", text:"Argentina"},
        {value:"AM", text:"Armenia"},
        {value:"AW", text:"Aruba"},
        {value:"AU", text:"Australia"},
        {value:"AT", text:"Austria"},
        {value:"AZ", text:"Azerbaijan"},
        {value:"BS", text:"Bahamas (the)"},
        {value:"BH", text:"Bahrain"},
        {value:"BD", text:"Bangladesh"},
        {value:"BB", text:"Barbados"},
        {value:"BY", text:"Belarus"},
        {value:"BE", text:"Belgium"},
        {value:"BZ", text:"Belize"},
        {value:"BJ", text:"Benin"},
        {value:"BM", text:"Bermuda"},
        {value:"BT", text:"Bhutan"},
        {value:"BO", text:"Bolivia (Plurinational State of)"},
        {value:"BQ", text:"Bonaire, Sint Eustatius and Saba"},
        {value:"BA", text:"Bosnia and Herzegovina"},
        {value:"BW", text:"Botswana"},
        {value:"BV", text:"Bouvet Island"},
        {value:"BR", text:"Brazil"},
        {value:"IO", text:"British Indian Ocean Territory (the)"},
        {value:"BN", text:"Brunei Darussalam"},
        {value:"BG", text:"Bulgaria"},
        {value:"BF", text:"Burkina Faso"},
        {value:"BI", text:"Burundi"},
        {value:"CV", text:"Cabo Verde"},
        {value:"KH", text:"Cambodia"},
        {value:"CM", text:"Cameroon"},
        {value:"CA", text:"Canada"},
        {value:"KY", text:"Cayman Islands (the)"},
        {value:"CF", text:"Central African Republic (the)"},
        {value:"TD", text:"Chad"},
        {value:"CL", text:"Chile"},
        {value:"CN", text:"China"},
        {value:"CX", text:"Christmas Island"},
        {value:"CC", text:"Cocos (Keeling) Islands (the)"},
        {value:"CO", text:"Colombia"},
        {value:"KM", text:"Comoros (the)"},
        {value:"CD", text:"Congo (the Democratic Republic of the)"},
        {value:"CG", text:"Congo (the)"},
        {value:"CK", text:"Cook Islands (the)"},
        {value:"CR", text:"Costa Rica"},
        {value:"HR", text:"Croatia"},
        {value:"CU", text:"Cuba"},
        {value:"CW", text:"Curaçao"},
        {value:"CY", text:"Cyprus"},
        {value:"CZ", text:"Czechia"},
        {value:"CI", text:"Côte d'Ivoire"},
        {value:"DK", text:"Denmark"},
        {value:"DJ", text:"Djibouti"},
        {value:"DM", text:"Dominica"},
        {value:"DO", text:"Dominican Republic (the)"},
        {value:"EC", text:"Ecuador"},
        {value:"EG", text:"Egypt"},
        {value:"SV", text:"El Salvador"},
        {value:"GQ", text:"Equatorial Guinea"},
        {value:"ER", text:"Eritrea"},
        {value:"EE", text:"Estonia"},
        {value:"SZ", text:"Eswatini"},
        {value:"ET", text:"Ethiopia"},
        {value:"FK", text:"Falkland Islands (the) [Malvinas]"},
        {value:"FO", text:"Faroe Islands (the)"},
        {value:"FJ", text:"Fiji"},
        {value:"FI", text:"Finland"},
        {value:"FR", text:"France"},
        {value:"GF", text:"French Guiana"},
        {value:"PF", text:"French Polynesia"},
        {value:"TF", text:"French Southern Territories (the)"},
        {value:"GA", text:"Gabon"},
        {value:"GM", text:"Gambia (the)"},
        {value:"GE", text:"Georgia"},
        {value:"DE", text:"Germany"},
        {value:"GH", text:"Ghana"},
        {value:"GI", text:"Gibraltar"},
        {value:"GR", text:"Greece"},
        {value:"GL", text:"Greenland"},
        {value:"GD", text:"Grenada"},
        {value:"GP", text:"Guadeloupe"},
        {value:"GU", text:"Guam"},
        {value:"GT", text:"Guatemala"},
        {value:"GG", text:"Guernsey"},
        {value:"GN", text:"Guinea"},
        {value:"GW", text:"Guinea-Bissau"},
        {value:"GY", text:"Guyana"},
        {value:"HT", text:"Haiti"},
        {value:"HM", text:"Heard Island and McDonald Islands"},
        {value:"VA", text:"Holy See (the)"},
        {value:"HN", text:"Honduras"},
        {value:"HK", text:"Hong Kong"},
        {value:"HU", text:"Hungary"},
        {value:"IS", text:"Iceland"},
        {value:"IN", text:"India"},
        {value:"ID", text:"Indonesia"},
        {value:"IR", text:"Iran (Islamic Republic of)"},
        {value:"IQ", text:"Iraq"},
        {value:"IE", text:"Ireland"},
        {value:"IM", text:"Isle of Man"},
        {value:"IL", text:"Israel"},
        {value:"IT", text:"Italy"},
        {value:"JM", text:"Jamaica"},
        {value:"JP", text:"Japan"},
        {value:"JE", text:"Jersey"},
        {value:"JO", text:"Jordan"},
        {value:"KZ", text:"Kazakhstan"},
        {value:"KE", text:"Kenya"},
        {value:"KI", text:"Kiribati"},
        {value:"KP", text:"Korea (the Democratic People's Republic of)"},
        {value:"KR", text:"Korea (the Republic of)"},
        {value:"KW", text:"Kuwait"},
        {value:"KG", text:"Kyrgyzstan"},
        {value:"LA", text:"Lao People's Democratic Republic (the)"},
        {value:"LV", text:"Latvia"},
        {value:"LB", text:"Lebanon"},
        {value:"LS", text:"Lesotho"},
        {value:"LR", text:"Liberia"},
        {value:"LY", text:"Libya"},
        {value:"LI", text:"Liechtenstein"},
        {value:"LT", text:"Lithuania"},
        {value:"LU", text:"Luxembourg"},
        {value:"MO", text:"Macao"},
        {value:"MG", text:"Madagascar"},
        {value:"MW", text:"Malawi"},
        {value:"MY", text:"Malaysia"},
        {value:"MV", text:"Maldives"},
        {value:"ML", text:"Mali"},
        {value:"MT", text:"Malta"},
        {value:"MH", text:"Marshall Islands (the)"},
        {value:"MQ", text:"Martinique"},
        {value:"MR", text:"Mauritania"},
        {value:"MU", text:"Mauritius"},
        {value:"YT", text:"Mayotte"},
        {value:"MX", text:"Mexico"},
        {value:"FM", text:"Micronesia (Federated States of)"},
        {value:"MD", text:"Moldova (the Republic of)"},
        {value:"MC", text:"Monaco"},
        {value:"MN", text:"Mongolia"},
        {value:"ME", text:"Montenegro"},
        {value:"MS", text:"Montserrat"},
        {value:"MA", text:"Morocco"},
        {value:"MZ", text:"Mozambique"},
        {value:"MM", text:"Myanmar"},
        {value:"NA", text:"Namibia"},
        {value:"NR", text:"Nauru"},
        {value:"NP", text:"Nepal"},
        {value:"NL", text:"Netherlands (the)"},
        {value:"NC", text:"New Caledonia"},
        {value:"NZ", text:"New Zealand"},
        {value:"NI", text:"Nicaragua"},
        {value:"NE", text:"Niger (the)"},
        {value:"NG", text:"Nigeria"},
        {value:"NU", text:"Niue"},
        {value:"NF", text:"Norfolk Island"},
        {value:"MP", text:"Northern Mariana Islands (the)"},
        {value:"NO", text:"Norway"},
        {value:"OM", text:"Oman"},
        {value:"PK", text:"Pakistan"},
        {value:"PW", text:"Palau"},
        {value:"PS", text:"Palestine, State of"},
        {value:"PA", text:"Panama"},
        {value:"PG", text:"Papua New Guinea"},
        {value:"PY", text:"Paraguay"},
        {value:"PE", text:"Peru"},
        {value:"PH", text:"Philippines (the)"},
        {value:"PN", text:"Pitcairn"},
        {value:"PL", text:"Poland"},
        {value:"PT", text:"Portugal"},
        {value:"PR", text:"Puerto Rico"},
        {value:"QA", text:"Qatar"},
        {value:"MK", text:"Republic of North Macedonia"},
        {value:"RO", text:"Romania"},
        {value:"RU", text:"Russian Federation (the)"},
        {value:"RW", text:"Rwanda"},
        {value:"RE", text:"Réunion"},
        {value:"BL", text:"Saint Barthélemy"},
        {value:"SH", text:"Saint Helena, Ascension and Tristan da Cunha"},
        {value:"KN", text:"Saint Kitts and Nevis"},
        {value:"LC", text:"Saint Lucia"},
        {value:"MF", text:"Saint Martin (French part)"},
        {value:"PM", text:"Saint Pierre and Miquelon"},
        {value:"VC", text:"Saint Vincent and the Grenadines"},
        {value:"WS", text:"Samoa"},
        {value:"SM", text:"San Marino"},
        {value:"ST", text:"Sao Tome and Principe"},
        {value:"SA", text:"Saudi Arabia"},
        {value:"SN", text:"Senegal"},
        {value:"RS", text:"Serbia"},
        {value:"SC", text:"Seychelles"},
        {value:"SL", text:"Sierra Leone"},
        {value:"SG", text:"Singapore"},
        {value:"SX", text:"Sint Maarten (Dutch part)"},
        {value:"SK", text:"Slovakia"},
        {value:"SI", text:"Slovenia"},
        {value:"SB", text:"Solomon Islands"},
        {value:"SO", text:"Somalia"},
        {value:"ZA", text:"South Africa"},
        {value:"GS", text:"South Georgia and the South Sandwich Islands"},
        {value:"SS", text:"South Sudan"},
        {value:"ES", text:"Spain"},
        {value:"LK", text:"Sri Lanka"},
        {value:"SD", text:"Sudan (the)"},
        {value:"SR", text:"Suriname"},
        {value:"SJ", text:"Svalbard and Jan Mayen"},
        {value:"SE", text:"Sweden"},
        {value:"CH", text:"Switzerland"},
        {value:"SY", text:"Syrian Arab Republic"},
        {value:"TW", text:"Taiwan"},
        {value:"TJ", text:"Tajikistan"},
        {value:"TZ", text:"Tanzania, United Republic of"},
        {value:"TH", text:"Thailand"},
        {value:"TL", text:"Timor-Leste"},
        {value:"TG", text:"Togo"},
        {value:"TK", text:"Tokelau"},
        {value:"TO", text:"Tonga"},
        {value:"TT", text:"Trinidad and Tobago"},
        {value:"TN", text:"Tunisia"},
        {value:"TR", text:"Turkey"},
        {value:"TM", text:"Turkmenistan"},
        {value:"TC", text:"Turks and Caicos Islands (the)"},
        {value:"TV", text:"Tuvalu"},
        {value:"UG", text:"Uganda"},
        {value:"UA", text:"Ukraine"},
        {value:"AE", text:"United Arab Emirates (the)"},
        {value:"GB", text:"United Kingdom of Great Britain and Northern Ireland (the)"},
        {value:"UM", text:"United States Minor Outlying Islands (the)"},
        {value:"US", text:"United States of America (the)"},
        {value:"UY", text:"Uruguay"},
        {value:"UZ", text:"Uzbekistan"},
        {value:"VU", text:"Vanuatu"},
        {value:"VE", text:"Venezuela (Bolivarian Republic of)"},
        {value:"VN", text:"Viet Nam"},
        {value:"VG", text:"Virgin Islands (British)"},
        {value:"VI", text:"Virgin Islands (U.S.)"},
        {value:"WF", text:"Wallis and Futuna"},
        {value:"EH", text:"Western Sahara"},
        {value:"YE", text:"Yemen"},
        {value:"ZM", text:"Zambia"},
        {value:"ZW", text:"Zimbabwe"},
        {value:"AX", text:"Åland Islands"}
      ],
      languageName: [
        // { value: 'select_language', text: 'Select Language' },
        {value: 'en', text: 'English'},
        {value: 'pt', text: 'Portuguese'},
        {value: 'fr', text: 'French'}
      ],
      currencyName: [
        {value: 'EUR', text: 'EUR'},
        {value: 'PLN', text: 'PLN'},
        {value: 'CZK', text: 'CZK'},
        {value: 'SEK', text: 'SEK'},
        {value: 'NOK', text: 'NOK'},
        {value: 'DKK', text: 'DKK'},
        {value: 'CHF', text: 'CHF'},
        {value: 'GBP', text: 'GBP'}
      ],
      checkList:"",
      checkConfirmDelete:"",
      error: "",
      description:"",
      confirmPassword:"",
      avatarText,

    }
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {resolveUserRoleVariant} = useUsersList()

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      resolveUserRoleVariant,
      avatarText,
      isChanged: true,
      refInputEl,
      previewEl,
      inputImageRenderer,
    }
  },
  methods: {
    formSubmitted() {
      let udpdate_profile_data =
          {
            company: this.currentUser.company,
            country: this.selectedContry.value,
            default_language: this.selectedLanguage.value,
            email: this.currentUser.email,
            first_name: this.currentUser.first_name,
            last_name: this.currentUser.last_name,
            phone_number: this.currentUser.phone_number,
            address: this.currentUser.address,
            state: this.currentUser.state,
            zip_code: this.currentUser.zip_code,
          }
      this.$store.dispatch("users/updateUserData", { context: this, data:udpdate_profile_data})
      .then(
        resp =>
        {
          this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Profile Updated',
            icon: 'EditIcon',
            variant: 'success',
            text:resp.data
          },
          })
        }
      )
      .then(
        this.$store.commit("users/SET_UPDATE_CURRENT_USER",udpdate_profile_data)
      )
      .catch(
        resp =>
        {
          this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Profile Updated Failed',
                      icon: 'AlertTriangleIcon',    
                      text: resp.data,
                      variant: 'danger'
                      },
})
        }
      )
      
    },
    enableChange() {
      this.isChanged = false
    },
    resetForm() {
      this.isChanged = true
    },
    showModal()
    {
      this.$refs.deleteForm.validate().then((success) => {
        if (success && this.checkConfirmDelete == true) {
          this.$refs['modal-center-delete'].show()
        }
      });
    },
    setValueCheck()
    {
      if(this.checkConfirmDelete == false )
        this.checkConfirmDelete = ""
    },
    clean(){
      this.checkList = ""
    },
    cancelAccount()
    {
      if(this.checkList == ""){
        this.error = "Please, select one checkbox"
        this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User Delete Failed',
                      icon: 'AlertTriangleIcon',    
                      text: this.error,
                      variant: 'danger'
                      },
                  })
      }
      if(this.confirmPassword == ""){
        this.error = "Please, insert your password"
        this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User Delete Failed',
                      icon: 'AlertTriangleIcon',    
                      text: this.error,
                      variant: 'danger'
                      },
                  })
      }
      else {
        let data = {
          "description": this.description,
          "reason_choice": this.checkList
        }
        this.$store.dispatch("users/postFeedback", { context: this, data:data})
        .then(resp =>
        {
          if(resp.status === 201){
            setTimeout(() => {
              const data = {"current_password":this.confirmPassword}
              this.$store.dispatch("users/deleteUser", { context: this, data})
              .then(resp =>    
              {
                if(resp.status === 204)
                {
                  
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: 'User Deleted',
                              icon: 'RefreshCwIcon',
                              variant: 'success',
                            },
                          })
                    this.$router.push({name:"logout"})
                }
              })
              .catch(resp_error => 
              {
                this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: 'User Delete Failed',
                        icon: 'AlertTriangleIcon',    
                        text: resp_error.response.data,
                        variant: 'danger'
                        },
                    })
              })
              }, 500)
          }
          else if(resp.status === 401)
          {
            this.$forceUpdate();
          }
        })
        .catch(resp_error => 
            {
              this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'User Delete Feedback Failed',
                      icon: 'AlertTriangleIcon',    
                      text: resp_error,
                      variant: 'danger'
                      },
                  })
            })
      }

    },
  },
  computed:{
    currentUser() {
        return this.$store.state.users.currentUser;
    },
    condition() {
      return false
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/base/pages/page-company.scss';
</style>
