<template>
  <div class="plans-middle second">
    <b-container>
      <b-row class="plan-payment-row">
        <b-col xl="3" class="payment-section">
          <b-tabs class="pay-tabs">
            <b-tab class="year-tab" active>
              <template #title>
                <span class="pay-title">
                  <span class="title-bold">{{$t("Annual Payment")}}</span>
                </span>
              </template>
              <b-card class="content-section remove-bottom-margin">
                <b-card-body>
                  <div class="plans-value">
                    <span class="month-value">
                      <span class="value-text">{{total_value}}</span>
                      <span class="value-simbol">€</span>
                    </span>
                    <span class="total-value">{{total_equipments}} {{$t("equipament")}}</span>
                    <span class="total-value">{{$t("6 months plan")}}</span>
                  </div>
                </b-card-body>
              </b-card>
              <b-card v-if="showPaypal" class="content-section paypal-container">
                <b-card-body>
                  <span class="payment-method-title">{{$t("Payment Method")}}</span>
                  <div id="paypal-button-container"></div>
                </b-card-body>
              </b-card>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col class="payment-table">
          <vue-good-table
            :columns="columns"
            :rows="deviceRows"
            :rtl="direction"
            :select-options="{
              enabled: true,
              selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
              selectionInfoClass: 'custom-class',
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              disableSelectInfo: true, // disable the select info panel on top
              selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
            }"
            :pagination-options="{
              enabled: true,
              perPage: pageLength,
              perPageDropdown: pageOptions,
              nextLabel: $t('Next'),
              prevLabel: $t('Prev'),
              rowsPerPageLabel: $t('Rows per page'),
              ofLabel: $t('of'),
            }"
            @on-selected-rows-change="onSelectedRowsChange"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'typology'" class="text-nowrap">
          <span class="text-nowrap">{{ $t(props.row.typology) }}</span>
        </span>

        <span v-else-if="props.column.field === 'serial_number'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.serial_number }}</span>
        </span>

        <span v-else-if="props.column.field === 'name'">
          <span class="text-nowrap">{{ props.row.name }}</span>
        </span>

        <span v-else-if="props.column.field === 'location'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.location }}</span>
        </span>

        <span v-else-if="props.column.field === 'expiration_date'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.expiration_date }}</span>
        </span>
        <span v-else-if="props.column.field === 'next_charge'" class="text-nowrap">
          <span class="text-nowrap">{{ props.row.next_charge }}</span>
        </span>
              <span v-if="props.column.field === 'plan'" class="text-nowrap">
                <b-badge :variant="planVariant(props.row.plan)">
                  {{ props.row.plan }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'status'" class="text-nowrap">
                <b-badge :variant="statusVariant(props.row.status)">
                  {{ props.row.status }}
                </b-badge>
              </span>
              <span v-else-if="props.column.field === 'cancel'">
                <b-badge variant=None>
                <feather-icon
                        v-if="validateCancelStatus(props.row.status)"
                        @click="openModalCancel(props.row)"
                        class="btn-cancel"
                        icon="Trash2Icon"
                    />
                </b-badge>
              </span>
            </template>
          </vue-good-table>
          <template #code>
            {{ codeBasic }}
          </template>
        </b-col>
      </b-row>
    </b-container>
    <b-modal
        id="modal-cancel-subscription"
        ref="modal-cancel-subscription"
        ok-only
        centered
        ok-title="Confirm"
        class="justify-content-center"
        @ok="cancelSubscription()"
    >
      <span class="modal-title">{{$t("Confirm Subscription Cancel")}}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
         <p>{{ $t("Do you realy want to cancel the subscription of this device:") }}</p>
         <p>{{$t("Device")}}: <b>{{equipment_to_cancel.serial_number}}</b></p>
         <p>{{$t("Description")}}: <b>{{equipment_to_cancel.description}}</b></p>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
        id="modal-confirm-update-subscription"
        ref="modal-confirm-update-subscription"
        centered
        class="justify-content-center"
        no-close-on-backdrop
        no-close-on-esc
        hide-footer
    >
      <span class="modal-title">{{$t("Confirm Subscription Cancel")}}</span>
      <b-row>
        <b-col cols="12" class="mt-1">
         <p>{{ $t("To complete subscription update, please confirm in this link:") }}</p>
         <p>{{ $t("This subscription has two or more devices linked, follow the link below to confirm the subscription update") }}</b></p>
         <a :href="link_confirm_subscription" target="_blank">{{ $t("Link") }}</a>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BListGroup,
  BListGroupItem,
  BCardText,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BLink,
  BProgress,
  BProgressBar,
  BTab,
  BTabs,
  BBadge
} from "bootstrap-vue";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import vSelect from "vue-select";
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import { codeBasic } from "./code-2";
import { loadScript } from "@paypal/paypal-js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { Trash2Icon } from "vue-feather-icons";

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BLink,
    BListGroupItem,
    BProgress,
    BProgressBar,
    BTab,
    BTabs,
    vSelect,
    VueGoodTable,
    ToastificationContent,
    BBadge
  },
  data() {
    return {
      link_confirm_subscription:"",
      subscription_data:{},
      equipment_to_cancel:{},
      showPaypal:false,
      value_per_equipment: 120,
      perPage: 1,
      pageLength: 5,
      pageOptions: [5, 10, 20],
      dir: false,
      totalRows: 3,
      currentPage: 1,
      codeBasic,
      columns: [
        {
          label: this.$t("TYPE"),
          field: "typology",
        },
        {
          label: this.$t("SERIAL No."),
          width: 'auto',
          field: "serial_number",
        },
        {
          label: this.$t("DESCRIPTION"),
          field: "name",
        },
        {
          label: this.$t("SITE"),
          field: "location",
        },
        {
          label: this.$t("PLAN"),
          field: "status",
          tdClass: 'text-center',
        },
        {
          label: this.$t("EXPIRATION DATE"),
          field: "expiration_date",
          formatFn: (value) => {
            if(value)
            {
              const dateTZ = new Date(`${value}`);
              const dayOfMonth =
              dateTZ.getDate() > 9 ? dateTZ.getDate() : `0${dateTZ.getDate()}`;
              const monthOfYear =
                dateTZ.getMonth() + 1 > 9
                  ? dateTZ.getMonth() + 1
                  : `0${dateTZ.getMonth() + 1}`;
              const year = dateTZ.getFullYear();
              const hourOfDay =
                dateTZ.getHours() > 9
                  ? dateTZ.getHours()
                  : `0${dateTZ.getHours()}`;
              const minuteOfHour =
                dateTZ.getMinutes() > 9
                  ? dateTZ.getMinutes()
                  : `0${dateTZ.getMinutes()}`;
              return `${year}-${monthOfYear}-${dayOfMonth}`;
            }
            else{
              return value
            }
          },
        },
        {
          label: this.$t("NEXT CHARGE"),
          field: "next_charge",
          formatFn: (value) => {
            if(value)
            {
              const dateTZ = new Date(`${value}`);
              const dayOfMonth =
              dateTZ.getDate() > 9 ? dateTZ.getDate() : `0${dateTZ.getDate()}`;
              const monthOfYear =
                dateTZ.getMonth() + 1 > 9
                  ? dateTZ.getMonth() + 1
                  : `0${dateTZ.getMonth() + 1}`;
              const year = dateTZ.getFullYear();
              const hourOfDay =
                dateTZ.getHours() > 9
                  ? dateTZ.getHours()
                  : `0${dateTZ.getHours()}`;
              const minuteOfHour =
                dateTZ.getMinutes() > 9
                  ? dateTZ.getMinutes()
                  : `0${dateTZ.getMinutes()}`;
              return `${year}-${monthOfYear}-${dayOfMonth}`;
            }
            else{
              return value
            }
          },
        },
        {
          field: "cancel",
          sortable: false,
        },
      ],
      exemple7: [
        {
          1: "Trial",
          2: "Premium",
          3: "No",
        },
        {
          1: "light-activo",
          2: "light-offline",
          3: "light-alerta",
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        "Trial": "trial",
        "No Plan": "no-plan",
        "Premium": "premium",
        "Processing": "processing"
        /* eslint-enable key-spacing */
      };

      return (status) => statusColor[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    deviceRows() {
      return  this.$store.state.plans.devicesListSubscription;
    },
    total_value() {
      let total = (this.total_equipments * this.value_per_equipment);
      this.$store.commit('plans/SET_TOTAL_VALUE_PLAN', total)
      return total;
    },
    total_equipments() {
      return this.$store.state.plans.total_equipments
    },
    selected_equipments(){
      return this.$store.state.plans.selected_equipments
    },
    plan_id(){
      return this.$store.state.plans.plan_id ? this.$store.state.plans.plan_id : 'P-80L59250W6644891NMUNH5SA'
    }

  },
  watch:{
    selected_equipments(newProp, oldProp){
      let custom_ids = newProp.map(obj => obj.mac_number).join(",")
      let quantity = this.total_equipments
      if(quantity == 0){
        this.clearRender()
      }
      else{
        this.clearRender()
        this.loadPaypal(this.total_equipments, custom_ids, this.plan_id, this.showNotification, this.errorNotification)
        this.showPaypal=true;
      }
    }
  },
  mounted() {
    this.totalRows = this.deviceRows.length;
  },
  methods: {
    validateCancelStatus(status){
      console.log(status)
      let enableCancelListStatus = ["Premium", "Processing", "Trial"]
      console.log(enableCancelListStatus.includes(status))
      if(enableCancelListStatus.includes(status))
          return true
        else
          return false
    },
    handlePaymentTabActivation(isActive = false) {
      this.$store.commit('app-user/SET_IS_PAYMENT_TAB_ACTIVE', isActive)
    },
    openDeviceDetails(props) {
      this.$router.push({
        name: "device",
        // preserve current path and remove the first char to avoid the target URL starting with `//`
        params: { id: props.row.id },
      });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    filterDeviceList() {
      this.$store.dispatch("devices/getRegisteredDevices", {
        context: this,
        typology: this.selectedDeviceType,
        search: this.selectedDevicesSearch,
      });
    },
    onSelectedRowsChange(rows) {
      this.$store.commit('plans/SET_TOTAL_EQUIPMENTS_PLAN', rows.selectedRows.length)
      this.$store.commit('plans/SET_LIST_EQUIPMENTS_PLAN', rows.selectedRows)
    },
    showNotification(){
      this.$toast({
            component: ToastificationContent,
            props: {
              icon: "CheckIcon",
              variant: "success",
              title: "Subscription Created",
              },
            });
      this.$store.commit('plans/SET_TOTAL_VALUE_PLAN', 0)
      this.$store.commit('plans/SET_TOTAL_EQUIPMENTS_PLAN', 0)
      this.$store.commit('plans/SET_LIST_EQUIPMENTS_PLAN', [])
    },
    errorNotification(){
      this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Subscription Failed',
                icon: 'AlertTriangleIcon',    
                variant: 'danger'
                },
            })
      this.$store.commit('plans/SET_TOTAL_VALUE_PLAN', 0)
      this.$store.commit('plans/SET_TOTAL_EQUIPMENTS_PLAN', 0)
      this.$store.commit('plans/SET_LIST_EQUIPMENTS_PLAN', [])
    },
    loadPaypal(quantity, custom_ids, plan_id, notificationFunc, errorNotificationFunc){
      loadScript({ "client-id": process.env.VUE_APP_PAYPAL_CLIENT_ID, "intent":"subscription", "vault":true})
      .then((paypal) => {
          paypal.Buttons({
            style: {
            layout:  'vertical',
            shape:   'pill',
            label:   'paypal',
          },
          createSubscription: function(data, actions) {
            return actions.subscription.create({
            'plan_id': plan_id,
            "quantity": quantity,
            "custom_id": custom_ids,
            });
          },
          onApprove: function() {
            notificationFunc();
          }
        }).render('#paypal-button-container')
      })
      .catch((error) => {
        errorNotificationFunc();
      });
    },
    clearRender() {
      const div = document.getElementById("paypal-button-container");
      if (div) {
        div.innerHTML = '';
      }
      this.showPaypal=false
    },
    openModalCancel(data){
      this.equipment_to_cancel=data;
      this.$refs['modal-cancel-subscription'].show()
    },
   async cancelSubscription(){
      this._validateSubscriptionQuantity()
    },
    _validateSubscriptionQuantity(){
      this.$store.dispatch("plans/getSubscription", { context: this, subscription_id:this.equipment_to_cancel.subscription_id})
      .then((res) => {
        this.subscription_data = res.data
        if(parseInt(this.subscription_data.quantity) > 1){
          this._updateSubscription(this.subscription_data)
        }
        else if (parseInt(this.subscription_data.quantity) == 1){
          this._cancelSubscription()
        }
      })
      .catch((error) => {
        this.notifyErrorCancel(error)
      });
    },
    async _cancelSubscription(){
      await this.$store.dispatch("plans/cancelSubscription", { context: this, subscription_id:this.equipment_to_cancel.subscription_id})
      .then((res) => {
        this.successCancel()
      })
      .catch((error) => {
        this.notifyErrorCancel(error)
      });
    },
    async _updateSubscription(subscription_data){
      let new_custom_ids = []
      let old_custom_ids = subscription_data.custom_id.split(',')
      old_custom_ids.forEach((element) => {
        if(element != this.equipment_to_cancel.mac_number){
          new_custom_ids.push(element)
        }
      });
      let new_custom_ids_payload = [{
        "op": "replace",
        "path": "/custom_id",
        "value": new_custom_ids.map(element => element).join(',')
        }]
      let old_custom_ids_payload = [{
        "op": "replace",
        "path": "/custom_id",
        "value": new_custom_ids.map(element => element).join(',')
        }]
      let data = {
        new_custom_id:new_custom_ids_payload,
        old_custom_ids:old_custom_ids_payload,
        subscription_id:this.equipment_to_cancel.subscription_id,
        new_quantity: `${parseInt(subscription_data.quantity) - 1}`,
        custom_id_deleted:this.equipment_to_cancel.mac_number,
        plan_id:this.plan_id
      }
      await this.$store.dispatch("plans/partialCancelSubscription", { context: this, data})
      .then((res) => {
        this.link_confirm_subscription = res.data.link_ref
        this.$refs['modal-confirm-update-subscription'].show()
      })
      .catch((error) => {
        this.notifyErrorCancel(error)
      });
    },
    notifyErrorCancel(error){
      this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cancel Subscription Failed',
                  icon: 'AlertTriangleIcon',    
                  variant: 'danger',
                  text: error
                  },
              })
    },
    successCancel(){
      this.$toast({
            component: ToastificationContent,
            props: {
              icon: "CheckIcon",
              variant: "success",
              title: `Subscription Cancelled for Device: ${this.equipment_to_cancel.description || this.equipment_to_cancel.mac_number}`,
              },
            });
    }
  },
  created() {
    this.$store.dispatch("plans/getDevicesListSubscription", { context: this })
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/base/pages/page-home.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";


.btn-cancel{
  color:rgb(91, 91, 91);
  align-content: end;
  cursor: pointer;
}

.plan-payment-row{
  margin-left: 0px;
  margin-right: 0px;
}

.paypal-container{
  background-color: #fff !important;
  border-left: solid 1px rgb(237, 240, 245);
  border-right: solid 1px rgb(237, 240, 245);
  border-bottom: solid 1px rgb(237, 240, 245);
  border-radius: 0px;
}

.remove-bottom-margin{
  margin-bottom: 0px !important;
}

.badge-processing {
    width: 100px;
    border-radius: 30px;
    background-color: #000 !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.badge-no-plan {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(64, 80, 130) !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
}

.badge-trial {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(47, 121, 187) !important;
    color: #fff !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.badge-premium {
    width: 100px;
    border-radius: 30px;
    background-color: rgb(237, 240, 245) !important;
    color: rgb(91, 91, 91) !important;
    font-family: 'TitilliumWeb-regular';
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
}

.payment-method-title{
  display: flex;
  width: 100%;
  height: 100px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: rgb(47, 121, 187);
  font-weight: bold;
  font-size: 25px;
}

@media (max-width: 2330px) {
  .payment-method-title{
    font-size: 20px;
  }
}

</style>