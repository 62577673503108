<template>
  <div>
    <b-card no-body class="plans-top">
      <b-card-body>
        <div class="plans-title">
          <img src="@/assets/images/icons/plano-white.png" alt="">
          <span class="text-plans">
            {{$t("Plans")}}
          </span>
        </div>
      </b-card-body>
    </b-card>
    <b-card class="plans-middle">
      <b-card-body>
        <b-row>
          <b-col xl="2" class="plans">
            <div class="month-plans">
              <div></div>
              <img src="@/assets/images/icons/plano-white.png" alt="">
              <span class="month-text">
                {{$t("Premium")}}
              </span>
            </div>
          </b-col>
          <b-col xl="4" class="exp-time">
            <span class="exp-title">{{$t("Premium Plan")}}</span>
            <span class="exp-description">{{$t("Benefit from the 6 months trial period.")}}<br/>
                  {{$t("Select the equipment you would like to upgrade to the premium plan and choose the payment method.")}}
            </span>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem, BCardText, BButton, BRow, BCol, BFormGroup, 
  BFormInput, BForm, BTable, BCard, BCardHeader, BCardTitle, BFormCheckbox,
  BProgress, BProgressBar
} from 'bootstrap-vue'
import BCardCode from '@core/components/b-card-code'
import vSelect from 'vue-select'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BCardCode,
    BListGroup,
    BCardText,
    BListGroupItem,
    BProgress,
    BProgressBar,
    vSelect,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {

    const permissionsData = [
      {
        type: 'New for you',
        email: true,
        browser: false,
        app: false,
      },
      {
        type: 'Account activity',
        email: false,
        browser: true,
        app: false,
      },
      {
        type: 'A new browser used to sign in',
        email: true,
        browser: false,
        app: true,
      },
      {
        type: 'A new device is linked',
        email: false,
        browser: false,
        app: false,
      },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      // eslint-disable-next-line no-param-reassign
      props.userData.avatar = base64
    })

    return {
      permissionsData,
      inputImageRenderer,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>